import React from "react"
import Button from "@material-ui/core/Button/Button"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Catalog from "../services/catalog"
import Bookmarks from "../data/bookmarks"
import { PartList, MachineList, ControlList } from "../components/items/item-list"

const bookmarkTypeToLookup = {
  parts: Catalog.getPartById,
  machines: Catalog.getMachineById,
  controls: Catalog.getControlPanelById,
};

class BookmarksPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      machines: [],
      parts: [],
      controls: [],
    };

    this.refreshBookmarkState = this.refreshBookmarkState.bind(this);
  }

  async refreshBookmarkState() {
    Object.entries(bookmarkTypeToLookup).forEach(([type, lookup]) => {
      Promise.all(Bookmarks.getBookmarkedItems(type).map(lookup))
      .then(results => this.setState({[type]: results}))
      .catch(console.error);
    });
    // Promise.all(Bookmarks.getBookmarkedItems('parts').map(Catalog.getPartById))
    //   .then(parts => this.setState({parts}))
    //   .catch(console.error);
    // Promise.all(Bookmarks.getBookmarkedItems('machines').map(Catalog.getMachineById))
    //   .then(machines => this.setState({machines}))
    //   .catch(console.error);
    // Promise.all(Bookmarks.getBookmarkedItems('controls').map(Catalog.getControlPanelById))
    //   .then(controls => this.setState({controls}))
    //   .catch(console.error);
  }

  componentDidMount() {
    this.refreshBookmarkState();
  }

  render() {
    const { parts, machines, controls } = this.state;

    return(
      <Layout navTab={4}>
        <SEO title="Part Browser" />
        <div style={{float: "right"}}>
          <Button color='primary' onClick={() => {
            Bookmarks.clearBookmarks('machines');
            Bookmarks.clearBookmarks('parts');
            Bookmarks.clearBookmarks('controls');
            
            this.refreshBookmarkState(this);
          }}
          >
            Clear
          </Button>
        </div>
        <h1>Bookmarks</h1>
        <h2>Machines</h2>
        <MachineList items={machines} bookmarkChanged={() => this.refreshBookmarkState(this)}/>
        <h2>Controls</h2>
        <ControlList items={controls} bookmarkChanged={() => this.refreshBookmarkState(this)}/>
        <h2>Parts</h2>
        <PartList items={parts} bookmarkChanged={() => this.refreshBookmarkState(this)}/>
      </Layout>
    );
  }
}

export default BookmarksPage